.box {
  @apply flex flex-col items-center justify-center;
  overflow: hidden;
  position: relative;
}
.btngroup {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  z-index: 9999999999;
  margin-bottom: 20px;
}
.shade {
  position: absolute;
  top: 40%;
  left: 50%;
  padding: 100px 100px 150px 100px;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  border-radius: 1000%;
  box-shadow: #ffffff 0 0 0 1000px;
}
#img {
  position: absolute;
  top: 1px;
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 10px 0px;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}
